import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SectionDropdownServiceService {

  constructor(private apiService: ApiService) { }

  GetDropDownValues(dropDownName): Observable<any> {
    return this.apiService.get(`Dropdown/${dropDownName}`);
  }

}
