import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule, HeaderComponent, FooterComponent } from './shared';

import { MaterialModule } from './material.module';

import { LoginModule } from './login/login.module';
import { UserInfoModule } from './user-info/user-info.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [
        AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,    
    LoginModule,
    UserInfoModule,
    HomeModule    
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
