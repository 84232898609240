import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { UserService } from './user.service';
import { JwtService } from './jwt.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private jwtService: JwtService
    ) { }

    canActivate(): Observable<boolean> | boolean {
        //if (this.jwtService.getToken()) {
            return this.userService.isAuthenticated.pipe(take(1));
          
          //  this.userService.purgeAuth();
        //} else {
        //    console.log('NO token');
        //    this.router.navigate(['/login']);
        //    return false;
        //}
    }
}
