import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { phneRes } from '..';


@Injectable({
  providedIn: 'root'
})
export class ParticipantRegService {

  constructor(private apiService: ApiService) { }

    saveSection(formData): Observable<any>{
        console.log(formData);
        return this.apiService.post('DomainValue/PostParticipantReg',formData);
    }

    checkPhoneNumberDuplicate(phoneNumber): Observable<phneRes> {
        return this.apiService.post(`DomainValue/PhoneNumberDuplicate/${phoneNumber}`).pipe(map(data => {  return data; }));
    }
}
