import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

    getSelectedDocument() {
        return sessionStorage.getItem('documentId');
  }

    getSelectedDocumentName() {
        return sessionStorage.getItem('documentName');
  }

    saveSelectedDocument(document) {
        sessionStorage.setItem('documentId', document.dB_ID);
        sessionStorage.setItem('documentName', document.name);
  }

    clearSelectedDocument() {
        if (sessionStorage.getItem('documentId')) {
            sessionStorage.removeItem('documentId');
            sessionStorage.removeItem('documentName');
    }
  }
    clearToken() {
        sessionStorage.removeItem('StayingConnectedIn');
  }

    setSearchFilter(filter) {
        sessionStorage.setItem('searchFilter', JSON.stringify(filter));
  }
    getSearchFilter(): any {
        return JSON.parse(sessionStorage.getItem('searchFilter'));
  }
    clearSearchFilter() {
        sessionStorage.removeItem('searchFilter');
  }

}
