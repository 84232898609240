import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import * as PRDateValidators from '../shared/validators/PRDateValidators';
import { SectionDropdownService, ParticipantRegService, AlertsService } from '../shared/services';
import { participantReg, phneRes } from '../shared/models';
import * as moment from 'moment';
import { MatStepper } from '@angular/material/stepper';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    parReg: participantReg = {} as participantReg;
    prForm: FormGroup;
    pcForm: FormGroup;
    agreeForm: FormGroup;
    emailValidator = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    relationships: any[];
    counties: any[];
    timeSlots: any[];
    years: any[];
    strCounty: string;
    strCity: string;
    strZipcode: string;
    skipStep;
    allAgree: boolean;
    diplayEdit: boolean = false;
    @ViewChild('stepper', { static: false }) stepper: MatStepper;
    isLinear = true;
    msg;
    errMsg: any[];
    isSubmitting = false;
    altContact: boolean = false;

    constructor(private fb: FormBuilder, private alertsService: AlertsService, private dropdownservice: SectionDropdownService, private participantRegService: ParticipantRegService, private router: Router) {
        this.prForm = this.fb.group({
            pr_FIRST_NAME: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            pr_LAST_NAME: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            pr_PHONE_NUMBER: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
            pr_VERIFY_PHONE_NUMBER: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
            pr_STREET_ADDRESS: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            pr_STREET_ADDRESS_2: new FormControl('', [ Validators.maxLength(50)]),
            pr_ZIP: new FormControl('', Validators.required),
            pr_CITY: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            pr_COUNTY: new FormControl('', Validators.required),
            pr_STATE: new FormControl('', Validators.required),
            PR_EMAIL_ADDRESS: new FormControl('', [Validators.maxLength(128), Validators.pattern(this.emailValidator)]),
            pr_BIRTH_YEAR: new FormControl('', [Validators.required, Validators.maxLength(4)]),
            pr_TIME_SLOT: new FormControl('', Validators.required),
            pr_ALT_CONTACT: new FormControl('', Validators.required)
        });
        this.pcForm = this.fb.group({
            pc_FIRST_NAME: new FormControl(''),
            pc_LAST_NAME: new FormControl(''),
            pc_PHONE_NUMBER: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
            pc_CONTACT_METHOD: new FormControl(''),
            pc_RELATIONSHIP: new FormControl(''),
        });
        this.agreeForm = this.fb.group({
           
            pr_REGISTRATION_DATE: new FormControl(''),
            pr_ATTESTATION_DATE: new FormControl('')
        });
    }


    ngOnInit() {

      
        this.dropdownservice.GetDropDownValues('RELATIONSHIPS').subscribe((data: any[]) => {
            this.relationships = data;
              this.alertsService.clearLoader();
        }, error => {
            //console.log(error);
             this.alertsService.error(error);
        });

        this.dropdownservice.GetDropDownValues('TIMESLOT').subscribe((data: any[]) => {
            this.timeSlots = data;
              this.alertsService.clearLoader();
        }, error => {
           // console.log(error);
             this.alertsService.error(error);
        });

        this.dropdownservice.getCounties().subscribe(data => {
            this.counties = data;
            this.alertsService.clearLoader();
        }, error => {
          //  console.log(error);
            this.alertsService.error(error);
        });



        const backSixty = new Date().getUTCFullYear() - 60;
        const now = new Date().getUTCFullYear();
        const yearsCal = Array(backSixty - (now - 150)).fill('').map((v, idx) => backSixty - idx);
        // console.log(yearsCal);
        this.years = yearsCal;
        this.onChanges();

    }
   
    getFormValidationErrors() {
        Object.keys(this.prForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.prForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    this.errMsg.push( 'Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    onChanges(): void {

        this.prForm.get('pr_BIRTH_YEAR').valueChanges.subscribe(dob => {

            if ((new Date()).getFullYear() - dob < 60) {
                //console.log((new Date()).getFullYear() - dob);
                this.prForm.get('pr_BIRTH_YEAR').setErrors({ invalid: true });
            }

        });
        this.pcForm.get('pc_PHONE_NUMBER').valueChanges.subscribe(altcont => {
          //  console.log(altcont);
            if (altcont == this.prForm.get('pr_PHONE_NUMBER').value) {
                this.pcForm.get('pc_PHONE_NUMBER').setErrors({ invalid: true });
            }
        });

        this.prForm.get('pr_PHONE_NUMBER').valueChanges.subscribe(cont => {
           
            if (cont == this.pcForm.get('pc_PHONE_NUMBER').value) {
                this.prForm.get('pr_PHONE_NUMBER').setErrors({ invalid: true });
            }
            // let phone = { phoneNumber: this.prForm.get('pr_PHONE_NUMBER').value };
            let phone = this.prForm.get('pr_PHONE_NUMBER').value;
            if (phone.length == 12) {
                // console.log(phone);

                this.participantRegService.checkPhoneNumberDuplicate(phone).subscribe((response: phneRes) => {
                    console.log(response);
                    if (Number(response.v_RETURN_VALUE) > 0) {
                        this.prForm.get('pr_PHONE_NUMBER').setErrors({ 'forbiddenName': true });
                        this.alertsService.clearLoader();
                    }

                }, err => {
                    console.log(err);
                        this.alertsService.error(err);
                });
            }
            this.alertsService.clearLoader();
        });

        this.prForm.get('pr_VERIFY_PHONE_NUMBER').valueChanges.subscribe(veriPhone => {
            //if (veriPhone.length == 12) {
            if (veriPhone != this.prForm.get('pr_PHONE_NUMBER').value) {
                this.prForm.get('pr_VERIFY_PHONE_NUMBER').setErrors({ invalid: true });
            }
            // }
        });

        this.prForm.get('pr_ALT_CONTACT').valueChanges.subscribe(alt => {
          //  console.log(alt);
            this.skipStep = alt;
            if (alt == 'Yes') {
                this.altContact = false;
                this.pcForm.reset();
                this.pcForm.get('pc_FIRST_NAME').setValidators([Validators.required, Validators.maxLength(60)]);
                this.pcForm.get('pc_FIRST_NAME').updateValueAndValidity();
                this.pcForm.get('pc_LAST_NAME').setValidators([Validators.required, Validators.maxLength(60)]);
                this.pcForm.get('pc_LAST_NAME').updateValueAndValidity();
                this.pcForm.get('pc_PHONE_NUMBER').setValidators([Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}"), Validators.required]);

                this.pcForm.get('pc_PHONE_NUMBER').updateValueAndValidity();
                this.pcForm.get('pc_RELATIONSHIP').setValidators([Validators.required]);
                this.pcForm.get('pc_RELATIONSHIP').updateValueAndValidity();

            } else if (alt == 'No')  {
                this.altContact = false;
                this.pcForm.get('pc_FIRST_NAME').clearValidators();
                this.pcForm.get('pc_FIRST_NAME').updateValueAndValidity();
                this.pcForm.get('pc_LAST_NAME').clearValidators();
                this.pcForm.get('pc_LAST_NAME').updateValueAndValidity();
                this.pcForm.get('pc_PHONE_NUMBER').clearValidators();
                this.pcForm.get('pc_PHONE_NUMBER').updateValueAndValidity();
                this.pcForm.get('pc_RELATIONSHIP').clearValidators();
                this.pcForm.get('pc_RELATIONSHIP').updateValueAndValidity();
            }

        });

        //this.agreeForm.get('pr_AGREE').valueChanges.subscribe(agree => {
        //  //  console.log(agree);
        //    this.allAgree = agree;
        //    if (agree) {
        //        this.agreeForm.patchValue({ pr_REGISTRATION_DATE: moment().format("MM-DD-YYYY") });
        //        this.agreeForm.patchValue({ pr_ATTESTATION_DATE: moment().format("MM-DD-YYYY") });
        //    }
        //    else {
        //        this.agreeForm.patchValue({ pr_REGISTRATION_DATE: moment().format("MM-DD-YYYY") });
        //        this.agreeForm.patchValue({ pr_ATTESTATION_DATE: '' });
        //    }
        //});

        this.prForm.get('pr_ZIP').valueChanges.subscribe(zipcode => {


            let zip = { ZipCode: this.prForm.get('pr_ZIP').value };

            if (zipcode != null && zipcode.length == 5) {
                console.log(zipcode.substring(0, 2));
                if ((zipcode.substring(0, 2) == '43') || (zipcode.substring(0, 2) == '44') || (zipcode.substring(0, 2) == '45')) {

                    this.dropdownservice.getCityState(zip).subscribe(
                        cityState => {

                            this.prForm.patchValue({ pr_CITY: cityState.city });

                            this.prForm.patchValue({ pr_COUNTY: cityState.countY_NAME });

                            this.prForm.patchValue({ pr_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });
                            this.alertsService.clearLoader();
                        }, err => {
                            console.log(err);
                              this.alertsService.error(err);
                        });

                } else {
                    this.prForm.get('pr_ZIP').setErrors({ invalid: true });
                    this.alertsService.clearLoader();
                }
            }
            else {

                this.prForm.patchValue({ pr_CITY: '' });
               this.prForm.patchValue({ pr_STATE: '' });
                this.prForm.patchValue({ pr_COUNTY: '' });
                this.alertsService.clearLoader();
            }

        }, err => {
            console.log(err);
             this.alertsService.error(err);
        });


    }

    move(index: number) {
        this.stepper.selectedIndex = index;
    }

    updateValues() {
        this.diplayEdit = true;
        if (this.prForm.get('pr_ALT_CONTACT').value == '') {
            this.altContact = true;
        } else {
            this.altContact = false;
        }
        this.updatePrFrm(this.prForm.value, this.pcForm.value, this.agreeForm.value);
    }

    submitForm() {      
        this.isSubmitting = true;
        this.updatePrFrm(this.prForm.value, this.pcForm.value, this.agreeForm.value);

        //console.log(this.parReg);
        this.participantRegService.saveSection(this.parReg).subscribe(response => {
            this.diplayEdit = false;
            console.log(response);
            this.msg = "Registration is Successful."

            setTimeout(() => {
               // this.router.navigateByUrl('/home');
                this.isSubmitting = false;
              
                this.diplayEdit = true;
                
                
            this.prForm.reset();
                this.pcForm.reset();

                location.reload();
                this.move(0);
                this.agreeForm.reset();
                this.msg = '';
                this.alertsService.clearLoader();
                
            }, 5000);
            this.alertsService.clearLoader();
        }, err => {
            this.isSubmitting = false;
            this.alertsService.error(err);
        });
    }

    updatePrFrm(prvalues: participantReg, pcvalues: participantReg, agreevalues: participantReg) {
        this.parReg.pr_FIRST_NAME = prvalues.pr_FIRST_NAME;
        this.parReg.pr_LAST_NAME = prvalues.pr_LAST_NAME;
        this.parReg.pr_PHONE_NUMBER = prvalues.pr_PHONE_NUMBER;
        this.parReg.pr_STREET_ADDRESS = prvalues.pr_STREET_ADDRESS;
        this.parReg.pr_STREET_ADDRESS_2 = prvalues.pr_STREET_ADDRESS_2;
        this.parReg.pr_ZIP = prvalues.pr_ZIP;
        this.parReg.pr_CITY = prvalues.pr_CITY;
        this.parReg.pr_COUNTY = prvalues.pr_COUNTY;
        this.parReg.pr_STATE = prvalues.pr_STATE;
        this.parReg.pr_BIRTH_YEAR = prvalues.pr_BIRTH_YEAR.toString();
        this.parReg.PR_EMAIL_ADDRESS = prvalues.PR_EMAIL_ADDRESS;
        this.parReg.pr_TIME_SLOT = prvalues.pr_TIME_SLOT;
        this.parReg.pr_ALT_CONTACT = prvalues.pr_ALT_CONTACT;
        if (this.skipStep == 'Yes') {
            this.parReg.pc_FIRST_NAME = pcvalues.pc_FIRST_NAME;
            this.parReg.pc_LAST_NAME = pcvalues.pc_LAST_NAME;
            this.parReg.pc_PHONE_NUMBER = pcvalues.pc_PHONE_NUMBER;
            this.parReg.pc_RELATIONSHIP = pcvalues.pc_RELATIONSHIP;
        }
        else {
            this.parReg.pc_FIRST_NAME = '';
            this.parReg.pc_LAST_NAME = '';
            this.parReg.pc_PHONE_NUMBER = '';
            this.parReg.pc_RELATIONSHIP = '';
        }
      
        this.parReg.pr_ATTESTATION_DATE = moment().format("MM-DD-YYYY");
        this.parReg.pr_REGISTRATION_DATE = moment().format("MM-DD-YYYY");
        //        Object.assign(this.parReg, prvalues);
    }
}
