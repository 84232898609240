import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from './home.component';
import { AuthGuard } from '../shared';
const routes: Routes = [
  {
    path: '',
        component: HomeComponent,
       
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
