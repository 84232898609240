import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import * as $ from 'jquery';
import { UserService, LoaderService, DataService, AuthService } from '../app/shared/services';
import { timer } from 'rxjs/observable/timer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private dataService: DataService,
    private authService: AuthService) {
      router.events.subscribe((event) => {
         // console.log(event);
          if (event instanceof NavigationEnd && event.url == '/') {
              localStorage.removeItem('StayingConnectedIn');
            //  console.log(localStorage.getItem('StayingConnectedIn'));
              let token = localStorage.getItem('StayingConnectedIn');
              if (token != null)  {
                  this.userService.purgeAuth();
              }
        
          //this.dataService.clearToken();
        this.loaderService.hideLoader();
      }
    });
    const source = timer(1000, 60000);
    const subscribe = source.subscribe(val => {     
        if (sessionStorage.getItem('StayingConnectedIn')) {     
        this.authService.showAuthRefresh();
      } 
    });
  
  }
  //TFS 2456 All Section - Move StayingConnectedIn ID and Consumer Name outside
  title = 'StayingConnectedIn';
 
    ngOnInit() {
    
    this.userService.populate();   
    //this.router.events.subscribe(event => {
    //    if (event instanceof NavigationEnd) {
    //        if (event.url == '/') {
    //            console.log(window.localStorage.getItem('StayingConnectedIn'));
    //            let token = window.localStorage.getItem('StayingConnectedIn');
    //            if (token == null) {
    //                this.userService.purgeAuth();
    //            }
    //        }
    //    }
    //});
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          this.loaderService.showLoader();
        } else {
          this.loaderService.hideLoader();
        }

      });
  }
}
