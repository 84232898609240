import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DropdownModel, CityState } from '../models/dropdown.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SectionDropdownService {

    constructor(private apiService: ApiService) { }

    GetDropDownValues(dropDownName): Observable<DropdownModel[]> {
        return this.apiService.get(`DomainValue/GetDropdownList/${dropDownName}`);
    }

    getCityState(zipCode): Observable<CityState> {

        return this.apiService.get('DomainValue/GetCityState', zipCode)
          .pipe(map(data => { return data; }));
      }

      getCounties(): Observable<any> {
        return this.apiService.get('DomainValue/getCounties');
      }

}
