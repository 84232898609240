import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version: string = environment.VERSION;
  year;
  constructor() { }

  ngOnInit() {
   // this.version = '1.0.0.0';
    this.year = new Date().getFullYear();
  }

}
