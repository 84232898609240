import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { UserService, User, AlertsService } from '../../shared';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  currentUser: User = {} as User;
  profileForm: FormGroup;
  isSubmitting = false;
  constructor(private userService: UserService,
    private fb: FormBuilder, private alertService: AlertsService) {
    this.profileForm = this.fb.group({
      userId: new FormControl({ value: '', disabled: true }),
      userActiveInd: new FormControl({ value: '', disabled: true }),
      userAgencyName: new FormControl({ value: '', disabled: true }),
      userAgencyType: new FormControl({ value: '', disabled: true }),
      userEmail: new FormControl({ value: '', disabled: true }),
      userName: new FormControl({ value: '', disabled: true }),
      userPhone: new FormControl({ value: '', disabled: true })
    });
  }

  ngOnInit() {
    Object.assign(this.currentUser, this.userService.getCurrentUser());
    this.profileForm.patchValue(this.currentUser);
    this.alertService.clearLoader();
  }

  submitForm() {
    this.isSubmitting = true;
    this.updateUser(this.profileForm.value);

    this.userService
      .update(this.currentUser)
      .subscribe(res => { this.alertService.success(res); },
        err => { this.alertService.error(err); });
    this.isSubmitting = false;
  }

  updateUser(values: Object) {
    Object.assign(this.currentUser, values);
  }
}
