import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models';
import { UserService, DataService, AlertsService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consumerinfo',
  templateUrl: './consumerinfo.component.html',
  styleUrls: ['./consumerinfo.component.css']
})
export class ConsumerinfoComponent implements OnInit {

  constructor(private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private alertService: AlertsService) { }
  profileUser: User;

  ngOnInit() {
    this.userService.currentUser.subscribe((userData: User) => { this.profileUser = userData; });
    this.alertService.clearLoader();
  }

}
